import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// bootstrap-icons
import {
  BIcon,
  BIconEye,
  BIconEyeSlash,
  BIconPin,
  BIconFileEarmarkPdfFill,
  BIconCheck2All,
  BIconTag,
  BIconArrowClockwise,
  BIconArrowRepeat,
  BIconCheckCircle
} from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.component('BIcon', BIcon)
Vue.component('BIconEye', BIconEye)
Vue.component('BIconEyeSlash', BIconEyeSlash)
Vue.component('BIconPin', BIconPin)
Vue.component('BIconPdfFilled', BIconFileEarmarkPdfFill)
Vue.component('BIconCheck2All', BIconCheck2All)
Vue.component('BIconTag', BIconTag)
Vue.component('BIconArrowRepeat', BIconArrowRepeat)
Vue.component('BIconArrowClockwise', BIconArrowClockwise)
Vue.component('BIconCheckCircle', BIconCheckCircle)

export default BootstrapVue
