const getters = {
  VN: (state) => state.versionNumber
}

const actions = {
  incrementVersion: ({ commit, state }) => {
    commit('setVersion', state.versionNumber + 1)
  }
}

const mutations = {
  setName: (state, data) => {
    state.appName = data
  },
  setVersion: (state, data) => {
    state.versionNumber = data
  }
}

const state = {
  appName: 'Birchwood',
  versionNumber: 1
}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
}
