<template>
  <div>
    <b-container fluid>
      <HeaderBar />
      <router-view />
    </b-container>
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue'
export default {
  name: 'DefaultLayout',
  components: { HeaderBar }
}
</script>
