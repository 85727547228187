import Vue from 'vue'
import Router from 'vue-router'
import DefaultLayout from '../components/Layouts/DefaultLayout.vue'

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    // {
    //   path: '/',
    //   name: 'landing',
    //   component: () =>
    //     import(/* webpackChunkName: "home" */ '@/components/LandingPage.vue')
    // },
    {
      path: '/',
      name: 'Login',
      component: () => import('@/components/pages/Login.vue')
    },
    {
      path: '/seeemail',
      name: 'seeemail',
      component: () => import('@/components/pages/EmailReader.vue')
    },
    {
      path: '/',
      name: 'DefaultLayout',
      component: DefaultLayout,
      children: [
        {
          path: '/home',
          name: 'Home',
          component: () => import('@/components/pages/Home.vue')
        },
        {
          path: '/orders',
          name: 'Orders',
          component: () => import('@/components/pages/Orders.vue')
        },
        {
          path: '/supplementOrders/:reportId',
          name: 'supplementOrders',
          component: () => import('@/components/pages/supplementOrders.vue')
        },
        {
          path: '/supplementSummary/:report/:id',
          name: 'supplementSummary',
          component: () => import('@/components/pages/supplementSummary.vue')
        }
      ]
    }
  ]
})

Vue.use(Router)

export default router
